import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import Quizz from "../components/Quizz/Quizz";

import { useAuth } from "@/modules/auth/AuthContext";
import Carousel from "@/modules/common/components/Carousel";
import TitledPage from "@/modules/common/components/layout/pages/TitledPage";
import SolutionCard from "@/modules/common/components/SolutionCard";
import pdf_bem_estar from "@/modules/home/assets/bem-estar.pdf";
import bem_estar from "@/modules/home/assets/bem-estar.png";
import check from "@/modules/home/assets/check.jpg";
import pdf_esportista from "@/modules/home/assets/dia-esportista.pdf";
import esportista from "@/modules/home/assets/dia-esportista.png";
import iva from "@/modules/home/assets/iva.jpg";
import tracking from "@/modules/home/assets/tracking.jpg";
import views from "@/modules/home/assets/views.jpg";
import pdf_webinar from "@/modules/home/assets/webinar.pdf";
import webinar from "@/modules/home/assets/webinar.png";

const carouselItems = [
  {
    title: "Venda mais em 2025 com o que aprendemos em 2024",
    description:
      "Uma análise detalhada dos dados de 2024 que revelam muito sobre o comportamento do consumidor para este ano.",
    imgPath: webinar,
    onClick: () => {
      window.open(pdf_webinar);
    },
  },
  {
    title: "Dia do Esportista: Vale o esportista do bairro?",
    description:
      "Enaltecendo os que encaram o exercício físico como mais uma jornada diária, coletamos alguns dados que revelam a relação do brasileiro com a atividade física.",
    imgPath: esportista,
    onClick: () => {
      window.open(pdf_esportista);
    },
  },
  {
    title: "Mente Sã, Corpo São",
    description:
      "Em dezembro de 2024, fizemos uma pesquisa com 1.224 brasileiros para descobrir um pouco mais da relação das pessoas com o bem-estar físico e mental.",
    imgPath: bem_estar,
    onClick: () => {
      window.open(pdf_bem_estar);
    },
  },
];

const Home = () => {
  const { user } = useAuth();
  const userFirstName = user?.name.split(" ")[0] ?? "";

  return (
    <TitledPage title={`${t("Hi")}, ${userFirstName}`}>
      <Grid container spacing={3} sx={{ mb: "40px" }}>
        <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
          <Box sx={{ flexGrow: 1, minHeight: "320px", maxWidth: "100%" }}>
            <Carousel items={carouselItems} />
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Quizz />
        </Grid>
      </Grid>
      <Stack sx={{ pb: 5 }}>
        <Stack direction="row" sx={{ mb: "12px" }}>
          <Typography
            sx={{
              flexGrow: 1,
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            <Trans>Discover our solutions</Trans>
          </Typography>
          <Link to="/products">
            <Button variant="text" size="small">
              <Trans>See all</Trans>
            </Button>
          </Link>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} xl={3}>
            <SolutionCard
              badge="Inteligencia competitiva"
              title="Pesquisas de Comportamento"
              description="Compreenda o seu consumidor e dialogue com ele a partir de dados e informações reais."
              imgPath={check}
              onClickLearnMore={() => {
                window.open(
                  "https://pinion.app/empresas-pesquisas-b2b/pesquisas-de-comportamento/"
                );
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <SolutionCard
              badge="Inteligencia competitiva"
              title="Pesquisas de Concorrência"
              description="Colete e monitore dados do mercado para identificar padrões e prever futuras direções."
              imgPath={iva}
              onClickLearnMore={() => {
                window.open(
                  "https://pinion.app/empresas-pesquisas-b2b/pesquisas-de-concorrencia/"
                );
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <SolutionCard
              badge="Inteligencia competitiva"
              title="Pesquisas de Publicidade"
              description="Teste antes de lançar! Examine performance e aceitação considerando percepções reais do seu público-alvo."
              imgPath={tracking}
              onClickLearnMore={() => {
                window.open(
                  "https://pinion.app/empresas-pesquisas-b2b/pesquisas-de-publicidade/"
                );
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <SolutionCard
              badge="Inteligencia competitiva"
              title="Pesquisas de Marca e Produto"
              description="Compreenda o seu consumidor e dialogue com ele a partir de dados e informações reais."
              imgPath={views}
              onClickLearnMore={() => {
                window.open(
                  "https://pinion.app/empresas-pesquisas-b2b/pesquisas-de-marca-e-produto/"
                );
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </TitledPage>
  );
};

export default Home;
